import {TimelineMax, gsap} from 'gsap';
import ScrollMagic from 'scrollmagic';
import slick from 'slick-carousel';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import "gsap/ScrollToPlugin";
import svg4everybody from 'svg4everybody';

ScrollMagicPluginGsap(ScrollMagic, TimelineMax);

svg4everybody();

jQuery(document).ready(function ($) {
    $('.hero__bg').slick({
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover:false,
      centerPadding: '0',
    });

    $('.recommendations-slider__slides').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        prevArrow: $('.recommendations-slider__left'),
        nextArrow: $('.recommendations-slider__right'),
        centerMode: true,
        centerPadding: '15%',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    arrows: true,
                    slidesToShow: 3,
                    centerPadding: '40px',
                    centerMode: true,
                }
            },
            {
                breakpoint: 900,
                settings: {
                    autoplay: false,
                    arrows: false,
                    slidesToShow: 1,
                    centerPadding: '30%',
                    centerMode: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    arrows: false,
                    autoplay: false,
                    slidesToShow: 1,
                    centerPadding: '100px',
                    centerMode: true,
                }
            },
            {
                breakpoint: 450,
                settings: {
                    arrows: false,
                    autoplay: false,
                    slidesToShow: 1,
                    centerPadding: '20px',
                    centerMode: true,
                }
            }
        ]
    });

    const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

    if(!isIE11) {
        const imageObserver  = new IntersectionObserver((entries, imgObserver) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting) {
                    const lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lzy_img");
                    imgObserver.unobserve(lazyImage);
                }
            });
        });

        const arr = document.querySelectorAll('img.lzy_img, iframe.lzy_img')
        arr.forEach((v) => {
            imageObserver.observe(v);
        });

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const item = entry.target;
                    item.classList.add('animation');

                    observer.unobserve(item);
                }
            });
        }, {rootMargin: '-100px'});

        const animArr = document.querySelectorAll(`[class^="obj"]`);

        animArr.forEach((n) => {
            observer.observe(n)
        });
    } else {
        $('img.lzy_img, iframe.lzy_img').each(function(){
            const link = this.getAttribute('data-src');

            this.setAttribute('src', link);
            this.classList.remove("lzy_img");
            this.classList.add("animation");
        });



        $('[class^="obj"]').addClass('animation')
    }



    const hamburger = document.querySelector(".hamburger");
    const html = document.documentElement;
    const close = document.querySelector(".mobile-menu__close");
    const mobile = document.querySelector(".mobile-menu");
    const overlay = document.querySelector(".overlay");


    const tlMobileMenu = gsap.timeline();
    tlMobileMenu
        .pause()
        .staggerFrom('.mobile-menu .line', .3, {
            opacity: 0,
            x: '-100%',
            ease: 'show',
        }, .06, 0.35)
        .to('.mobile-menu .logo', 0,
            {z: 0.001, opacity: 0, x: '-50'}, 0)
        .to('.mobile-menu .logo', .3,
            {z: 0.001, opacity: 1, x: '0'}, .3)
        .staggerFrom('.mobile-menu .nav a', .35, {
            opacity: 0,
            x: '-100%',
            ease: 'show',
        }, .035, 0.45)
        .staggerFrom('.mobile-menu .contact-blocks__item', .4, {
            opacity: 0,
            x: '-100px',
            ease: 'show',
        }, .035, 0.75)


    function runmenu(what) {
        if (what === true) {
            tlMobileMenu.play().timeScale(1);
            mobile.classList.add('is-active');
            mobile.style['transition-duration'] = '.3s';
            overlay.classList.add('is-active');
            html.classList.add('no-scroll');
        } else {
            tlMobileMenu.pause(0).timeScale(2).reverse();
            mobile.classList.remove('is-active');
            overlay.classList.remove('is-active');
            html.classList.remove('no-scroll');
        }
    }

    hamburger.addEventListener("click", () => {
        runmenu(true);
    });

    close.addEventListener("click", () => {
        runmenu(false);
    });

    overlay.addEventListener("click", () => {
        runmenu(false);
    });

    $('body').on('click', '.mobile-menu__nav a', function() {
        runmenu(false)
    });


    $('body').on('click', '.guarantee-tabs__item', function () {
        const $this = $(this);
        const tab = Number($this.attr('data-tab'));
        const windowWidth = window.innerWidth;


        function animate() {
            const $html = $(`.guarantee-tab[data-tab="${tab}"]`).html();

            if ($this.hasClass('is-active')) {
                $this.removeClass('is-active');
            } else {
                $('.guarantee-tabs__item.is-active').removeClass('is-active');
                $this.addClass('is-active');
                $this.after($html);
                const $list = $('.guarantee-tabs__header .section__list');
                const curHeight = $list.height()
                $list.css('height', 'auto');
                const autoHeight = $list.height();
                $list.height(curHeight).animate({height: autoHeight}, 300);
            }
        }

        function slide() {
            $('.guarantee-tabs__item.is-active').removeClass('is-active');
            $this.addClass('is-active');

            $.each($('.guarantee-tab'), function (i, item) {
                const $thisTab = $(item);
                const val = -100 * tab;

                $thisTab.css('transform', `translateX(${val}%)`)
            });
        }

        if (windowWidth <= 375) {

            const $old = $('.guarantee-tabs__header .section__list');

            if ($old.length) {
                $old.animate({height: 0}, 100,
                    function () {
                        $old.remove();
                        animate()
                    });
            } else {
                animate()
            }
        } else {
            slide()
        }
    });



    let lastScrollTop = 0;
    const $header = $('.header');
    const $headerButton = $header.find('.button');
    const headerHeight = $('header').outerHeight();

    $(window).scroll(function () {
        const scroll = $(window).scrollTop();

        if (scroll < lastScrollTop && scroll <= 0) {
            $header.removeClass('fixed')
            $headerButton.removeClass('button--fill')
            $headerButton.addClass('button--outline')
        } else {
            $header.addClass('fixed');
            $headerButton.addClass('button--fill')
            $headerButton.removeClass('button--outline')
        }

        if (scroll > lastScrollTop && scroll > headerHeight * 2 || scroll === 0) {
            $header.removeClass('is-active')
        } else {
            $header.addClass('is-active');
        }

        lastScrollTop = scroll;
    });


    const avatarDiv = document.querySelector('.avatar-cursor');

    const animateit = function (e) {
        // let { clientX: x, clientY: y } = e || e.touches[0];

        let x = e.clientX;
        let y = e.clientY;

        if(e.touches && e.touches.length > 0) {
            x = e.touches[0].clientX;
            y = e.touches[0].clientY;
        } else if (!e.clientX) {
            x = e.changedTouches[0].clientX;
            y = e.changedTouches[0].clientY;
        }

        const {offsetWidth: width, offsetHeight: height} = avatarDiv;
        const {left, top} = this.getBoundingClientRect();
        const move = 35;

        const windowWidth = window.innerWidth;

        const style = avatarDiv.currentStyle || window.getComputedStyle(avatarDiv);
        const marginLeft = Math.abs(parseInt(style.marginLeft, 10));

        let xMove = (1 - (x - left) / width) * move;
        let yMove = (1 - (y - top) / height) * move;


        xMove = (xMove < 0) ? left : left - xMove;
        yMove = (yMove < 0) ? top : top - yMove;

        const calc = xMove + width + marginLeft;

        if (calc >= windowWidth) {
            xMove = windowWidth - width - 10;
        }
        if (xMove < 0) {
            xMove = 10;
        }

        avatarDiv.style.transform = `translate(${xMove}px, ${yMove}px)`;
        avatarDiv.style.left = 0;
        avatarDiv.style.top = 0;
        avatarDiv.style.opacity = 1;

        if (e.type === 'mouseleave') {
            console.log(e.target.classList);
            avatarDiv.style.width = null;
            avatarDiv.style.height = null;
            avatarDiv.style.marginLeft = null;
            avatarDiv.style.marginTop = null;
            // avatarDiv.style.borderRadius = null;
            avatarDiv.style.opacity = null;
        }

    };

    const copyAvatar = function (e) {

        if ((e.type === 'mouseleave' || e.type === 'touchend') && $(e.target).hasClass()) {
            avatarDiv.innerHTML = '';
        } else {
            avatarDiv.appendChild(this.cloneNode(true));

            gsap.fromTo('.teams__avatar-name', .4,
                {delay: .1, opacity: 0, x: '-16', ease: 'show'},
                {opacity: 1, x: '0', ease: 'show'});
            gsap.fromTo('.teams__avatar-position', .4,
                {delay: .3, opacity: 0, x: '-16', ease: 'show'},
                {opacity: 1, x: '0', ease: 'show'});
            gsap.fromTo('.teams__avatar-bottom', .4,
                {delay: .3, opacity: 0, x: '-16', ease: 'show'},
                {opacity: 1, x: '0', ease: 'show'});
        }
    };

    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    // const avatar = document.querySelectorAll('.teams__avatar:not(.teams__empty):not(.teams__empty-dark)');

    // avatar.forEach(b => b.addEventListener('mouseenter', animateit));
    // avatar.forEach(b => b.addEventListener('mouseleave', animateit));
    // avatar.forEach(b => b.addEventListener('mouseenter', copyAvatar));
    // avatar.forEach(b => b.addEventListener('mouseleave', copyAvatar));
    //
    // avatar.forEach(b => b.addEventListener('touchstart', animateit));
    // avatar.forEach(b => b.addEventListener('touchend', animateit));
    // avatar.forEach(b => b.addEventListener('touchstart', copyAvatar));
    // avatar.forEach(b => b.addEventListener('touchend', copyAvatar));


    $(".js-scroll").click((e) => {
        e.preventDefault();

        $('html,body').animate({
            scrollTop: $(".contact-form").offset().top
        }, 'slow');
    });

    $('body').on('click', 'a[href*="#"]', function(e) {
        e.preventDefault();

        const $this = $(this);
        const id = $this.attr('href');

        const top = id ? $(id).offset().top : 0;

        $('body,html').animate({
            scrollTop: top
        }, 400);
    });



    $("#submit").submit((e) => {
        e.preventDefault();

        const $message = $('.contact-form__message');
        $message.html('').removeClass('contact-form__message--bad');

        const $form = $("#submit");

        $.ajax({
            url: "/php/sendForm.php",
            type: 'POST',
            data: $form.serialize(),
            dataType: "json",
            success(data) {
                if (data.success) {
                    $message.html('Сообщение отправлено')
                }
            },
            error(request, status, error) {
                $message.html('Не удалось отправить сообщение 😔').addClass('contact-form__message--bad')
            }
        });
    });

    const animation = (windowWidth) => {
        const height = parseInt($('.angle').css('height'), 10);

        const controller = new ScrollMagic.Controller();

        // const maxwidth =  parseInt($('.auto-one').css('max-width'), 10);

        let first = 130;
        const fromFirst = -50;
        let two = 650;
        let fromTwo = 500;
        // const plane = 50;
        // const tanker = 0;
        // const last = 0;

        if(windowWidth <= 1400) {
            first = 320;
            two = 720;
            fromTwo = 200;
        }

        if(windowWidth <= 1200 && windowWidth >= 860) {
            first = 100;
            two = 500;
            fromTwo = 200;
        }

        if(windowWidth <= 860 && windowWidth >= 0) {
            first = 80;
            two = 400;
            fromTwo = 200;
        }

        const type = new TimelineMax();
        type
            // line 1
            .fromTo('.auto-one', .75,
                {z:0.001, x: fromFirst}, {z:0.001, x: first, opacity: 1}, "+=.5")
            .fromTo('.auto-one span', .3,
                {z:0.001, x: -50,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.25")
            .fromTo('.auto-two', .75,
                {z:0.001, x: fromTwo}, {z:0.001, x: two, opacity: 1}, "-=.5")
            .fromTo('.auto-two span', .3,
                {z:0.001, x: -150,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.25")
            // line 2
            .fromTo('.auto-four', .75,
                {z:0.001, x: fromFirst * -1}, {z:0.001, x: first * -1, opacity: 1}, "-=.5")
            .fromTo('.auto-four span', .3,
                {z:0.001, x: 50,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.25")
            .fromTo('.auto-three', .75,
                {z:0.001, x: fromTwo * -1}, {z:0.001, x: two * -1, opacity: 1}, "-=.5")
            .fromTo('.auto-three span', .3,
                {z:0.001, x: 50,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.25")
            // // line 3
          .fromTo('.auto-five', .75,
            {z:0.001, x: fromFirst}, {z:0.001, x: first, opacity: 1}, "-=.5")
          .fromTo('.auto-five span', .3,
            {z:0.001, x: -150,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.5")
          .fromTo('.auto-six', .75,
            {z:0.001, x: fromTwo}, {z:0.001, x: two, opacity: 1}, "-=.5")
          .fromTo('.auto-six span', .3,
            {z:0.001, x: -150,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.5")
            // // line 4
            .fromTo('.auto-seven', 1,
                {z:0.001, x: two}, {z:0.001, x: 0, opacity: 1}, "-=.7")
            .fromTo('.auto-seven span', .7,
                {z:0.001, x: 50,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=.5")
            // // line 5
            .fromTo('.plane', 1.5,
                {z:0.001, x: -400, y: -75,  opacity: 0}, {z:0.001, x: 50, y: 0, opacity: 1}, "-=.6")
            .fromTo('.tanker', 1.25,
                {z:0.001, x: -120, y: -25,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=1.35")
            .fromTo('.auto-last', 1,
                {z:0.001, x: -300,  opacity: 0}, {z:0.001, x: -100, opacity: 1}, "-=1.35")
            .fromTo('.auto-last span', .6,
                {z:0.001, x: -50,  opacity: 0}, {z:0.001, x: 0, opacity: 1}, "-=1.7");


        const sceneType = new ScrollMagic.Scene({
            duration: '275%',
            offset: -300,
            triggerElement: '.types-transport',
            triggerHook: .8,
        });

        // sceneType.addIndicators({name: 'type'});
        sceneType.setTween(type);
        sceneType.addTo(controller);


        const cloudsType = new TimelineMax();
        cloudsType
            .to('.clouds_bg', 1,
                {z: 0.001, y: -100, x: -40}, 0);
            // .to('.clouds-1', 1,
            //     {z: 0.001, y: -60, scale: 1.2}, 0)
            // .to('.clouds-2', 1,
            //     {z: 0.001, y: -70, scale: 1.2}, 0)
            // .to('.air', 1,
            //     {z: 0.001, x: -200, scale: 1.2}, 0)
            // .to('.clouds-3', 1,
            //     {z: 0.001, y: -55, scale: 1.2}, 0)
            // .to('.clouds-4', 1,
            //     {z: 0.001, y: -35, scale: 1.2}, 0)
            // .to('.clouds-5', 1,
            //     {z: 0.001, y: -130, scale: 1.2}, 0)
            // .to('.clouds-6', 1,
            //     {z: 0.001, y: -20, scale: 1.2}, 0)
            // .to('.clouds-7', 1,
            //     {z: 0.001, y: -80, scale: 1.2}, 0)
            // .to('.clouds-8', 1,
            //     {z: 0.001, y: -80, scale: 1.2}, 0)
            // .to('.clouds-9', 1,
            //     {z: 0.001, y: -90, scale: 1.2}, 0);


        const sceneCloudsType = new ScrollMagic.Scene({
            duration: '200%',
            offset: 0,
            triggerElement: '.types-transport',
            triggerHook: .9,
        });

        sceneCloudsType.addIndicators({name: 'clouds'});
        sceneCloudsType.setTween(cloudsType);
        // scene.setPin('.anim');
        sceneCloudsType.addTo(controller);





        const clouds = new TimelineMax();
        clouds
            .to('.about__img', 1,
                {z: 0.001, y: -50, scale: 0.93}, 0)
            .to('.about__info ', 1,
                {z: 0.001, y: -100}, 0)
            .to('.angle', 1,
                {z: 0.001, height: height - 200}, 0)
            // .to('.about-list__item:nth-child(1)', 1,
            //     {z: 0.001, y: -120}, 0)
            // .to('.about-list__item:nth-child(2)', 1,
            //     {z: 0.001, y: -180}, 0)
            // .to('.about-list__item:nth-child(3)', 1,
            //     {z: 0.001, y: -210}, 0)
            // .to('.about-list__item:nth-child(4)', 1,
            //     {z: 0.001, y: -250}, 0)
            // .to('.about-list__item:nth-child(5)', 1,
            //     {z: 0.001, y: -200}, 0)
            // .to('.about-list__item .about-list__number', 1,
            //     {z: 0.001, y: -80}, 0)
            // .to('.about-list__item .about-list__title', 1,
            //     {z: 0.001, y: -50}, 0)
            // .to('.about-list__item .about-list__description', 1,
            //     {z: 0.001, y: -30}, 0)
            .to('.cloud--1', 1,
                {z: 0.001, y: -60, scale: 1.2}, 0)
            .to('.cloud--2', 1,
                {z: 0.001, y: -70, scale: 1.2}, 0)
            .to('.air', 1,
                {z: 0.001, x: -200, scale: 1.2}, 0)
            .to('.cloud--3', 1,
                {z: 0.001, y: -55, scale: 1.2}, 0)
            .to('.cloud--4', 1,
                {z: 0.001, y: -35, scale: 1.2}, 0)
            .to('.cloud--5', 1,
                {z: 0.001, y: -130, scale: 1.2}, 0)
            .to('.cloud--6', 1,
                {z: 0.001, y: -20, scale: 1.2}, 0)
            .to('.cloud--7', 1,
                {z: 0.001, y: -80, scale: 1.2}, 0)
            .to('.cloud--8', 1,
                {z: 0.001, y: -80, scale: 1.2}, 0)
            .to('.cloud--9', 1,
                {z: 0.001, y: -90, scale: 1.2}, 0)
            .to('.tanker', 1,
                {z: 0.001, y: -100, x: 80, scale: 1.2}, 0)
            // .to('.about .section__button', 1,
            //     {z: 0.001, y: -120}, 0)


        const scene = new ScrollMagic.Scene({
            duration: '150%',
            offset: -200,
            triggerElement: '.about',
            triggerHook: 0,
        });

        // scene.addIndicators({name: 'clouds'});
        scene.setTween(clouds);
        // scene.setPin('.anim');
        scene.addTo(controller);


        const imgAnim = new TimelineMax();
        imgAnim
            .to(".section-images__scroll", 1, {x: "-10%"});

        const sceneImageContaier = new ScrollMagic.Scene({
            duration: '130%',
            offset: 0,
            triggerElement: '.section-images',
            triggerHook: 1,
        });

        // sceneImageContaier.addIndicators({name: 'image'});
        sceneImageContaier.setTween(imgAnim);
        sceneImageContaier.addTo(controller);


        const teams = new TimelineMax();
        teams
            .to(".teams__bg", 1, {y: "30%"});

        const sceneTeams = new ScrollMagic.Scene({
            duration: '150%',
            offset: 0,
            triggerElement: '.teams',
            triggerHook: 1,
        });

        // sceneTeams.addIndicators({name: 'image'});
        sceneTeams.setTween(teams);
        sceneTeams.addTo(controller);
    };

    const toggleSlider = function () {
        const windowWidth = window.innerWidth;

        if (windowWidth <= 375) {
            $(".guarantee-tabs__item.is-active").removeClass('is-active')
        }

        if (windowWidth > 769) {
            animation(windowWidth)
        }
    };

    toggleSlider();

});
